<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Hostitelé
          <v-spacer></v-spacer><v-form v-model="searchValid" class="d-flex align-center">
          <v-text-field
            ref="searchInput"
            v-model.trim="search"
            append-icon="mdi-magnify"
            :rules="[validation.required]"
            label="Vyhledat"
            single-line
            hide-details
            outlined
            dense
            class="rounded-0"
          ></v-text-field>
          <v-select
            outlined
            class="rounded-0"
            dense
            single-line
            hide-details
            ref="searchTypeInput"
            label="Vyhledat podle"
            :rules="[validation.required]"
            v-model="searchType"
            :items="searchTypes"
          >
          </v-select>
          <v-btn
            color="primary"
            :disabled="!searchValid || runningRequest"
            @click="filterBySearch"
            class="rounded-0"
          >Vyhledat</v-btn>
          <v-btn
            color="error"
            @click="resetSearch()"
            class="rounded-0"
            :disabled="!activeSearch || runningRequest"
          >
            Zrušit filtr
          </v-btn>
        </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="hosts"
          :loading="runningRequest"
          class="elevation-1"
          :server-items-length="totalHosts"
          :options.sync="options"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
          <template v-slot:item.street="{ item }">
            {{item.street}} {{item.streetNumber}}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
export default {
  name: 'Hosts',
  data () {
    return {
      search: '',
      searchType: null,
      activeSearch: false,
      searchValid: true,
      headers: [
        { text: 'Jméno', align: 'start', value: 'firstName' },
        { text: 'Příjmení', value: 'lastName' },
        { text: 'ID', value: 'id' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Telefon 2', value: 'phone2' },
        { text: 'Ulice a čp.', value: 'street' },
        { text: 'PSČ', value: 'zip' },
        { text: 'Město', value: 'city' },
        { text: 'Počet objektů', value: 'propertiesCount' },
        { text: 'Kredity', value: 'credit' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      hosts: [],
      totalHosts: 0,
      options: {},
      searchTypes: [],
      runningRequest: false,
      exactRoute: true,
      hostToDelete: {}
    }
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.loadHosts()
      }
    }
  },
  methods: {
    loadHosts () {},
    getHosts () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.$http.get('/admin/hosts/archive', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.hosts = res.data.hosts
          this.totalHosts = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    filterBySearch () {
      this.activeSearch = true
      this.getHosts()
    },
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.activeSearch = false
      this.getHosts()
    },
    redirectToDetail (host) {
      this.$router.push(`/admin/host-archive/${host.id}`)
    }
  },
  mounted () {
    this.searchTypes = this.headers.slice(0, -3)
    this.getHosts()
    this.loadHosts = this.debounce(this.getHosts, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
